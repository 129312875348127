import React, { useEffect, useState } from "react";
import { ConfigService, ProductsService } from "../../services";
import { CodeScanner, CustomAlert, CustomButton, Loader } from "../../components";
import { Image, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";
import { isEmpty } from "../../utils/utils";

import { useLocalStorage } from "../../hooks";

import "../../styles/Home.css";
export interface Props {}

export const HomeScreen: React.FunctionComponent<Props> = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showNoProductAlert, setShowNoProductAlert] = useState<boolean>(false);
  const [camera, setCamera] = useState<boolean>(true);
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [valueStored, storeValue] = useLocalStorage("client", {
    branchId: null,
    clientId: null,
    description: null,
    id: null,
    name: null,
    productId: null,
    type: null,
    value: null,
    token: null,
    filled: false,
  });

  const navigate = useNavigate();

  const fetchProduct = (productCode: string) => {
    console.log(productCode);
    ProductsService.get(
      valueStored.clientId.toString(),
      valueStored.token,
      productCode
    )
      .then((data) => {
        console.log(isEmpty(data));
        if (!isEmpty(data)) {
          navigate(`/product`, {
            state: { data },
          });
          setCamera(false);
        } else {
          alertTimeout("Producto no disponible.");
        }
      })
      .catch((error) => {
        console.log(error);
        alertTimeout(
          "Tenemos problemas con el servidor. Sera solucionado a la brevedad."
        );
      });
  };

  const alertTimeout = (msg: string) => {
    setAlertMsg(msg);
    setShowNoProductAlert(true);
    setTimeout(() => setShowNoProductAlert(false), 3000);
  };

  useEffect(() => {
    let name: any = "";
    if (window.location.host.split(".").length > 1) {
      name = window.location.host.split(".");
      if (name[0].includes("www")) name = name[1];
      else name = name[0];
    } else {
      name = window.location.pathname.split("/");
      name = name[1];
    }

    if (valueStored.clientId === null && name !== "") {
      console.log("fetch client");
      ConfigService.get(name)
        .then((data) => {
          if (!isEmpty(data)) {
            const nombre = data.name.toLowerCase();
            data.name = nombre;
            console.log("cliente: " + nombre);
            storeValue(data);
            setLoading(false);
          }
        })
        .catch((error) => console.log(error));
    } else {
      setLoading(false);
    }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="home-grid-container">
      <CustomAlert
        variant="danger"
        show={showNoProductAlert}
        close={() => {
          setShowNoProductAlert(false);
        }}
        content={alertMsg}
      />
      <header>
        <Image
          className="client-logo"
          src={`${config.API.URL}/images/${valueStored.name}.png`}
          onError={(e) => {
            e.currentTarget.src = `${config.API.URL}/images/logodefault.png`;
          }}
        />
      </header>
      <div className="scanner-container-row">
        <div className="text-row">
          <p>Para ver el precio, escaneá el código</p>
        </div>
        <div className="scanner-row">
          <CodeScanner
            height={400}
            width={320}
            setData={fetchProduct}
            show={camera}
            closeOnSetData={false}
          />
        </div>
      </div>
      <div className="button-row">
        <CustomButton
          style={{ fontSize: 20 }}
          content="Ingrese el código del producto"
          // click={() => navigate(`/name/manualcode`)}
          click={() => navigate(`/manualcode`)}
          show={true}
        />
      </div>
      {/* {height > 715 ? (
        <footer>
          <div className="invel-logo-row">
            <Image
              className="invel-logo"
              src={`${config.API.URL}/images/logo-1.png`}
            />
          </div>
        </footer>
      ) : null} */}
    </div>
  );
};
