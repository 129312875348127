import React, { useState, useCallback, useRef, useEffect } from "react";
import { BrowserMultiFormatReader } from "@zxing/browser";
import Webcam from "react-webcam";
import { BinaryBitmap, DecodeHintType } from "@zxing/library";

export interface Props {
  show: boolean;
  // close: () => void;
  setData: (data: string) => void;
  width: number;
  height: number;
  closeOnSetData?: boolean;
}
export const CodeScanner: React.FunctionComponent<Props> = (props: Props) => {
  const hints = new Map<DecodeHintType, any>();
  hints.set(DecodeHintType.TRY_HARDER, true);

  const webcamRef = useRef<any>(null);
  const codeReader = new BrowserMultiFormatReader(hints);
  const [intervalId, setIntervalId] = useState<any>();

  const capture = useCallback(() => {
    if (webcamRef.current !== null) {
      const imageSrc = webcamRef.current.getScreenshot();

      if (imageSrc) {
        let result = codeReader
          .decodeFromImageUrl(imageSrc)
          .then(async (result: any) => {
            props.setData(result.text)!;
            console.log(result.text);
            await new Promise((resolve) => {
              console.log("timeout");
              window.setTimeout(() => {}, 1000);
              resolve(null);
            });
          })
          .catch(async (err) => {});
      }
    }
  }, [codeReader, props.setData]);

  useEffect(() => {
    if (props.show) setIntervalId(setInterval(capture, 50));
    else clearInterval(intervalId);
  }, [props.show]);

  return props.show ? (
    <div>
      <Webcam
        width={props.width}
        height={props.height}
        ref={webcamRef}
        forceScreenshotSourceSize={true}
        imageSmoothing={true}
        screenshotQuality={1}
        screenshotFormat="image/png"
        videoConstraints={{
          facingMode: "environment",
        }}
      />
    </div>
  ) : null;
};
