/*
 * Metodo que pasa los precios a string con '.' y ','
 * @param price float a parsear
 * @return string parseado
 */

export function parsePriceStr(price: string): string {
  let auxPrice: string = price;

  // console.log("Precio lista: " + auxPrice);

  // cambiamos el punto por coma
  auxPrice = auxPrice.replace(".", ",");

  let strArr: Array<string> = auxPrice.split(""); // array del string

  let counter: number = 0;
  let index: number = strArr.indexOf(",");

  for (let i = index; i > 0; i--) {
    // recorremos el array de derecha a izquierda
    // cada 3 numeros, desde la coma, ponemos un punto
    if (counter % 3 === 0 && counter !== 0) {
      strArr.splice(i, 0, ".");
    }
    counter++;
  }

  auxPrice = "";
  // creamos el string
  for (let i in strArr) {
    auxPrice += strArr[i];
  }

  return auxPrice;
}


export function isEmpty(obj: Object) {
  return Object.keys(obj).length === 0;
}