import { FetchService } from "./Fetch.service";

export const ConfigService = (() => {
  const serviceEndpoint = "/config";
  const get = (name?: string) => {
    return FetchService.callApi({
      endpoint: serviceEndpoint + (name ? "/" + name : ""),
      method: "GET",
    });
  };

  return {
    get,
  };
})();
