import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomeScreen, CodeScreen, ProductScreen } from "../screens";

export interface Props {}

export const AppNavigation: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<HomeScreen />} />
        <Route path="/" element={<HomeScreen />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route path="/manualcode" element={<CodeScreen />} />
        <Route path="/product" element={<ProductScreen />} />
      </Routes>
    </Router>
  );
};
