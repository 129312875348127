import { FetchService } from "./Fetch.service";

export const ProductsService = (() => {
  const serviceEndpoint = "/products";
  const get = (clientId: string, token: string, productCode?: string) => {
    console.log(`${clientId} ${productCode}`);
    return FetchService.callApi({
      endpoint:
        serviceEndpoint +
        "/" +
        clientId +
        (productCode ? "/" + productCode : ""),
      method: "GET",
      auth: token,
      // customHeaders: tokenHeader,
    });
  };

  return {
    get,
  };
})();
