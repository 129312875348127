import React from "react";
import Button from "react-bootstrap/Button";

export interface Props {
  click: () => void;
  content: any;
  show?: boolean;
  className?: string;
  disabled?: boolean;

  style?: {};
}

export const CustomButton: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Button
      className={props.className}
      style={props.style}
      onClick={props.click}
      disabled={props.disabled}
    >
      {props.content}
    </Button>)
};
