import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { CustomForm, CustomButton, CustomAlert } from "../../components";
import { useNavigate } from "react-router-dom";
import { ConfigService, ProductsService } from "../../services";
import { config } from "../../config";
import { isEmpty } from "../../utils/utils";
import { useLocalStorage } from "../../hooks";

import "../../styles/Code.css";

export interface Props {}

export const CodeScreen: React.FunctionComponent<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [prod, setProd] = useState<string>("");
  const [showNoProductAlert, setShowNoProductAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>("");

  const [valueStored, storeValue] = useLocalStorage("client", {
    branchId: null,
    clientId: null,
    description: null,
    id: null,
    name: null,
    productId: null,
    type: null,
    value: null,
    token: null,
    filled: null,
  });

  const fetchProduct = (productCode: string) => {
    ProductsService.get(
      valueStored.clientId.toString(),
      valueStored.token,
      productCode
    )
      .then((data) => {
        console.log(data);
        if (!isEmpty(data)) {
          navigate(`/product`, {
            state: { data },
          });
        } else {
          alertTimeout("Producto no disponible.");
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
        alertTimeout(
          "Tenemos problemas con el servidor. Sera solucionado a la brevedad."
        );
      });
  };

  const alertTimeout = (msg: string) => {
    setAlertMsg(msg);
    setShowNoProductAlert(true);
    setTimeout(() => setShowNoProductAlert(false), 3000);
  };

  return (
    <div className="code-grid-container">
      <CustomAlert
        variant="danger"
        show={showNoProductAlert}
        close={() => {
          setShowNoProductAlert(false);
        }}
        content={alertMsg}
      />

      <header>
        <div className="client-logo-row">
          <Image
            className="client-logo"
            src={`${config.API.URL}/images/${valueStored.name}.png`}
            onError={(e) => {
              e.currentTarget.src = `${config.API.URL}/images/logodefault.png`;
            }}
          />
        </div>
      </header>
      <div className="form-row">
        <CustomForm
          className="form"
          label="Ingresa el código para consultar su precio"
          placeHolder="Código"
          text={prod}
          setText={setProd}
        />
        <CustomButton
          className="price-button"
          style={{ fontSize: 20, maxWidth: 220 }}
          click={() => {
            if (prod) fetchProduct(prod);
          }}
          show={true}
          content="Consultar precio"
          disabled={showNoProductAlert}
        />
      </div>
      <div className="buttons-row">
        <CustomButton
          className="back-button"
          style={{ fontSize: 20, maxWidth: 160 }}
          click={() => navigate(`/home`)}
          show={true}
          content="Regresar"
        />
      </div>
      {/* {!isKeyboardOpen ? (
        <footer>
          <div className="invel-logo-row">
            <Image
              className="invel-logo"
              src={`${config.API.URL}/images/logo-1.png`}
            />
          </div>
        </footer>
      ) : null} */}
    </div>
  );
};
