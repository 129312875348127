import React from "react";
import { Table } from "react-bootstrap";
import { idText } from "typescript";
import "../../styles/StockVisualizer.css";

export interface Props {
  data: any;
}

export const StockVisualizer = ({ data }: Props) => {
  return (
    <div className="stock-table">
      <Table striped="columns" responsive="sm" size="sm" variant="light">
        <tbody>
          {Object.keys(data.stock).map((key) => {
            return (
              <tr>
                <td className="text-center" key={data.branch_name[key]}>
                  {key}
                </td>
                <td className="text-center" key={key}>
                  {data.stock[key]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>

  );
};
