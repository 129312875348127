import React, { useState } from "react";

export const useLocalStorage = (key: string, initialValue: any) => {
  const [valueStored, setValueStored] = useState(() => {
    try {
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const storeValue = (value: any) => {
    try {
      setValueStored(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  };

  return [valueStored, storeValue];
};
