import React, {useState} from "react";
import { Form } from "react-bootstrap";

export interface Props{
  className: string;
  label: string;
  placeHolder: string;
  text: string;
  setText: (form_content:string) => void;
  type?: string;
}

export const CustomForm: React.FunctionComponent<Props> = (props: Props) => {
  

  return (
    <Form className={props.className}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        type={props.type ? props.type : "text"}
        placeholder={props.placeHolder}
        value={props.text}
        onChange={(content: any) => props.setText(content.target.value)}
      />
    </Form>
  );
};
