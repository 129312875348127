import React from "react";
import { Spinner } from "react-bootstrap";
import "../../styles/Loader.css"

export interface Props {}
export const Loader = (props: Props) => {
  return (
    <div className="loading-container">
      <Spinner
        className="loading-spinner"
        animation="border"
        variant="secondary"
      />
      <p className="loading-text">Cargando...</p>
    </div>
  );
};
