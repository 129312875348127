import React, { useState } from "react";
import { Alert } from "react-bootstrap";

export interface Props {
  className?: string;
  variant: string;
  show: boolean;
  close: (state: boolean) => void;
  content: string;
}

export const CustomAlert: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Alert
      variant={props.variant}
      className={props.className}
      dismissible
      show={props.show}
      onClose={props.close}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        margin: 15,
        marginLeft: 15,
        marginRight: 15,
      }}
    >
      <Alert.Heading>Ups! hubo un error. </Alert.Heading>
      <p>{props.content}</p>
    </Alert>
  );
};
