import React, { useEffect, useState } from "react";
import "./App.css";
import { AppNavigation } from "./navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import { ConfigService } from "./services";
import { useLocalStorage } from "./hooks";

function App() {
  // const [valueStored, storeValue] = useLocalStorage("client", {
  //   branchId: null,
  //   clientId: null,
  //   description: null,
  //   id: null,
  //   name: null,
  //   productId: null,
  //   type: null,
  //   value: null,
  //   token: null,
  //   filled: false,
  // });

  

  // useEffect(() => {
  //   let name: any = "";
  //   name = window.location.pathname.split("/");
  //   name = name[1];

  //   if (valueStored.clientId === null) {
  //     console.log("fetch client");
  //     ConfigService.get(name)
  //       .then((data) => {
  //         const nombre = data.name.toLowerCase();   
  //         data.name = nombre;
  //         console.log("cliente: " + nombre);
  //         storeValue(data);
  //       })
  //       .catch((error) => console.log(error));
  //   }
  // }, []);

  return <AppNavigation />;
}

export default App;
