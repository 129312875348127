import { config } from "../config";

type HTTP_METHODS = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export const FetchService = (() => {
  const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  const callApi = async ({
    endpoint,
    method = "GET",
    formData = false,
    data = {},
    customHeaders = {},
    auth = undefined,
    includeHeaders = [],
    retryOnUnAuth = true,
  }: {
    endpoint: string;
    method: HTTP_METHODS;
    formData?: boolean;
    data?: any;
    customHeaders?: any;
    auth?: string;
    includeHeaders?: string[];
    retryOnUnAuth?: boolean;
  }) => {
    if (!auth) {
      try {
        auth = "Bearer " + (await localStorage.getItem("at"));
      } catch (error) {
        auth = "";
      }
      // console.log("!auth" + auth);
    }
    customHeaders = {
      ...customHeaders,
      Authorization: "Bearer " + auth,
    };

    // console.log("custom headers: " +customHeaders);

    let doFetchParams = {
      url: config.API.URL + endpoint,
      method: method,
      data: data,
      customHeaders: customHeaders,
      formData: formData,
      includeHeaders: includeHeaders,
    };
    // console.log("doFetchParams");

    // console.log(doFetchParams);

    return new Promise<any>((resolve, reject) => {
      doFetch(doFetchParams)
        .then((res) => resolve(res))
        .catch(async (error) => {
          // if ((error.code == "TOKEN-EXPIRED" || error.code == "INVALID-TOKEN") && retryOnUnAuth) {
          //   try {
          //     let currentTokens ={
          //       accessToken: localStorage.getItem('at'),
          //       refreshToken: localStorage.getItem('rt')
          //     }
          //     let response = await LoginService.refreshTokens(currentTokens.refreshToken);
          //     if (response.code == "REFRESHED-TOKEN") {

          //       localStorage.setItem('at', response.data.accessToken);
          //       localStorage.setItem('rt', response.data.refreshToken);

          //       return doFetch(doFetchParams);
          //     }
          //     reject(error);
          //   } catch (error) {
          //     reject(error);
          //   }
          // } else {
          //   reject(error);
          // }
          reject(error);
        });
    });
  };

  const doFetch = async ({
    url,
    method = "GET",
    formData = false,
    data = {},
    customHeaders = {},
    includeHeaders,
  }: {
    url: string;
    method: HTTP_METHODS;
    data?: any;
    customHeaders?: any;
    formData: boolean;
    includeHeaders: string[];
  }) => {
    let queryParams = "";

    let fetchOpt: any = {};
    fetchOpt.method = method ? method : "GET";
    if (fetchOpt.method.toUpperCase() != "GET") {
      if (formData) {
        let form = new FormData();
        for (const name in data) {
          if (Array.isArray(data[name])) {
            data[name].forEach((element: any) => {
              form.append(name, element);
            });
          } else {
            form.append(name, data[name]);
          }
        }
        fetchOpt.body = form;
      } else {
        fetchOpt.body = JSON.stringify(data);
      }
    } else {
      queryParams = "?" + serialize(data);
    }
    let headers = {};
    if (formData) {
      headers = {};
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }

    headers = { ...headers, ...customHeaders };

    fetchOpt.headers = headers;

    return new Promise<any>((resolve, reject) => {
      var branches = {},
        stocks: {};

      // console.log("fetching: " + url + " " + queryParams);
      // console.log(fetchOpt);

      fetch(url + queryParams, fetchOpt)
        .then(async (response) => {
          if (response.ok) {
            if (response.status == 204) {
              resolve({});
            } else {
              let res: any;
              try {
                if (response) {
                  res = await response.json();
                  // console.log(res);

                  /* format data */
                  if (url.includes("products")) {
                    if (res[0].stock !== undefined) {
                      for (var i = 0; i < res.length; i++) {
                        stocks = { ...stocks, [res[i].branch_name]: res[i].stock };
                      }
                      res = { ...res[0], stock: stocks };
                    } else res = res[0];
                  }
                  // console.log(res);
                }
              } catch (error) {
                console.log(error);
              }
              if (includeHeaders.length > 0) {
                res = { body: res, headers: {} };
                includeHeaders.forEach((header) => {
                  res.headers[header] = response.headers.get(header);
                });
              }
              resolve(res);
            }
          } else {
            let res: any;
            try {
              res = await response.json();
            } catch (error) {
              //console.log(error);
            }
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return {
    serialize: serialize,
    doFetch: doFetch,
    callApi: callApi,
  };
})();
